/* Adjusting deals board on small screens */
@media screen and (max-width: 1280px) {
  html:has(div.dealBoard) {
    zoom: 90%;
  } 
  div.dealBoard .MuiCard-root {
    zoom: 97%;
  }  
}
@media screen and (max-width: 1180px) {
  html:has(div.dealBoard) {
    zoom: 85%;
  }
  div.dealBoard .MuiCard-root {
    zoom: 97%;
  }   
}      
@media screen and (max-width: 1080px) {
  html:has(div.dealBoard) {
    zoom: 80%;
  }
  div.dealBoard .MuiCard-root {
    zoom: 93%;
  } 
}
@media screen and (max-width: 880px) {
  html:has(div.dealBoard) {
    zoom: 75%;
  }
  div.dealBoard .MuiCard-root {
    zoom: 93%;
  }  
}
/* Adjusting deals board on small screens */ 